import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faList,faPlus } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus, faList, faWallet, faUser, faEnvelope, faMobile } from "@fortawesome/free-solid-svg-icons";
// import DepositINRReject from "../partials/DepositINRReject";
// import DepositINRAppove from "../partials/DepositINRAppove";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from '../../actions/baseUrl';
import Moment from 'moment';
const baseUrl = myConstList.baseUrl;
class UserDepositHistory extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                // key: "id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell: (row, index) => index + 1
            },
            {
                key: "id",
                text: "sno",
                className: "id",
                align: "left",
                sortable: true

            },
            {
                key: "tx_id",
                text: "Transaction_id",
                className: "transaction_id",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <span style={{ wordBreak: "break-all" }}>{record.tx_id}</span>
                    )

                }
            },
            {
                key: "wallet_address",
                text: "Wallet address",
                className: "transaction_id",
                align: "left",
                sortable: true,
            },
            {
                key: "email",
                text: "Email",
                className: "transaction_id",
                align: "left",
                sortable: true,
            },
            {
                key: "firstName",
                text: "Name",
                className: "transaction_id",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <>  <FontAwesomeIcon icon={faUser} /> <span>{record.firstName + " " + record.lastName}</span><br />
                            {/* <FontAwesomeIcon icon={faEnvelope} /><span style={{marginLeft:"2px"}}>{record.email}</span><br /> */}
                            <FontAwesomeIcon icon={faMobile} /> <span> {record.mobile_no}</span><br />
                            {/* <span style={{ wordBreak: "break-all",cursor:"pointer" }}  onClick={() => {
                                                              navigator.clipboard.writeText(record.wallet_address);
                                                              toast.success("Copied");
                                                            }}>   <FontAwesomeIcon icon={faWallet} />  {record.wallet_address}</span>  <i className="fa fa-clone"                       
                                                          ></i> */}
                                                          </>
                    );
                }
            },
            // {
            //     key: "email",
            //     text: "Email",
            //     className: "email",
            //     align: "left",
            //     sortable: true,
            // },
            // {
            //     key: "mobile_no",
            //     text: "Mobile No.",
            //     className: "email",
            //     align: "left",
            //     sortable: true
            // },


            {
                key: "amount",
                text: "Amount",
                className: "amount",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <span>
                            {Math.abs(record.coin_amount)}
                        </span>
                    );
                }
            },
            {
                key: "transaction_fees",
                text: "Fees",
                className: "amount",
                align: "left",
                sortable: true
            },
            {
                key: "name",
                text: "	Cryptocoin",
                className: "coinName",
                align: "left",
                sortable: true,

            },
            {
                key: "usdt_chain_type",
                text: "Token",
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "created",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {Moment(record.created).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "status",
                text: "Status",
                className: "date",
                align: "left",
                sortable: true,
                // cell: record => {
                //     return (
                //         <Fragment>
                //             <span>Pending</span>
                //         </Fragment>
                //     );
                // }
            },
            // {
            //     key: "action",
            //     text: "Action",
            //     className: "action",
            //     width: 100,
            //     align: "left",
            //     sortable: false,
            //     cell: record => {
            //         return (

            //             <Fragment>
            //                 <p className="mb-0">

            //                     {record.status == 'pending' ? ( <>
            //                         <button
            //                     data-toggle="modal"
            //                     data-target="#update-user-modal-approve"
            //                     className="btn btn-primary btn-sm min_w90"
            //                     onClick={() => this.editRecord(record)}
            //                     >
            //                     <i className="fa fa-check  ml-1"></i>
            //                 </button>
            //                 <button
            //                     data-toggle="modal"
            //                     data-target="#update-user-modal-reject"
            //                     className="btn btn-primary btn-sm min_w90"
            //                     onClick={() => this.editRecord(record)}
            //                    >
            //                     <i className="fa fa-close  ml-1"></i>
            //                 </button>
            //                     </>

            //                     ) : record.status == 'completed' ? (     <button
            //                     data-toggle="modal"
            //                     data-target="#update-user-modal-approve"
            //                     className="btn btn-primary btn-sm min_w90"
            //                     onClick={() => this.editRecord(record)}
            //                     >
            //                    <span className="completed_btn2">Approved<i className="fa fa-check  ml-1"></i></span>
            //                 </button>) : ( <>
            //                         <button
            //                     data-toggle="modal"
            //                     data-target="#update-user-modal-reject"
            //                     className="btn btn-primary btn-sm min_w90" 
            //                     onClick={() => this.editRecord(record)}
            //                     >
            //                     <i className="fa fa-close  ml-1"></i>
            //                 </button> </>
            //                     )}
            //                 </p>
            //             </Fragment>

            //         );
            //     }
            // }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {

                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: '',
                coin_amount: '',
                user_id: '',
                approve: '',
                reject: "",
                status: ''
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
        this.getCoinData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()

    }

    getCoinData() {
        axios.get(baseUrl + "/api/crypto-data").then((res => {
            if (res.status == 200) {
                this.setState({ records1: res.data, status: res.status })
                console.log("MyRecord", this.state.records1)
            }
        }))
    }

    showFilterList = () => {
        if (this.state.status == 200) {
            const html = []
            this.state.records1.forEach((e, i) => {
                console.log(e)
                html.push(
                    <option value={e.short_name}>{e.name}</option>
                );
            })
            return <select className="form-control" id="exchange" name="withdrawalFilter" onChange={this.onSelectChange.bind(this)}>
                <option value="">All</option>
                {html}</select>
        }

    }

    getData() {
        axios
            .get(baseUrl + "/api/userCoinDepositHistory")
            .then(res => {
                this.setState({ records: res.data, tempData: res.data })
            })
            .catch((e) => {
                console.log(e);
            })
    }

    onSelectChange(event) {

        axios
            .post(baseUrl + "/api/userFilterCoinwithdrawalHistory", { WithdrawlRequest: event.target.value })
            .then((res) => {
                console.log("res.data :", res.data)
                if (event.target.value != "") {
                    this.setState({ records: res.data });
                } else {
                    this.setState({ records: this.state.tempData });
                }
            })
            .catch();
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }



    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    {/* <DepositINRReject record={this.state.currentRecord}/>
                    <DepositINRAppove record={this.state.currentRecord}/> */}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mb-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>

                            <h4 className="mt-2 man_hadding mb-3">Deposit History</h4>

                            <div className="white_box">
                                <div className="support-select">
                                    {this.showFilterList()}
                                </div>
                                <div className="yuyu">
                                    <ReactDatatable
                                        config={this.config}
                                        records={this.state.records}
                                        columns={this.columns}
                                        onPageChange={this.pageChange.bind(this)}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

UserDepositHistory.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(UserDepositHistory);
