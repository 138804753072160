import React, { Component, Fragment } from "react";
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import BOTAddModal from "./BOTAddModal";
import BOTUpdateModal from "./BOTUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import Moment from 'moment';
import Switch from "react-switch";
import $ from 'jquery';
import {baseUrl} from "../../../actions/baseUrl";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
class BOT extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.columns = [
            {
                key: "id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "email",
                text: "User Name",
                className: "name",
                align: "left",
                sortable: true,
                cell: record=>{
                    return (<>
                    <span>{record.email} </span>
                    </>
                    )
                   
                }
            },
            {
                key: "order_type",
                text: "Order Type",
                className: "name",
                align: "left",
                sortable: true,
                cell: record=>{
                    return (<>
                    <span>{record.order_type} </span>
                    </>
                    )
                }
            },
            {
                key: "name1",
                text: "Pair Name",
                className: "name",
                align: "left",
                sortable: true,
                cell: record=>{
                    return (<>
                    <span>{record.name1} /</span>
                    <span>{record.name2} </span>
                    </>
                    )
                   
                }
            },
           
            {
                key: "created",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.created_at).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "status",
                text: "Available for Order",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          <Switch onChange={() => this.handleChange(record)} checked={record.status=='1'?true:false}/>
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-user-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}}>
                                <i className="fa fa-edit"></i>
                            </button>
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecordMgs(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "BOT",
            no_data_text: 'No user found!',
            button: {
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            pairList:[],
            userList:[],
            checked: false
        };

        this.state = {
            currentRecord: {
                id: '',
                user_id: '',
                pair_id: '',
                order_type: '',
              },
              switchchecked: false
          };

        this.getData = this.getData.bind(this);
        console.log("this.getData",this.getData)
    }

    handleChange(record) {
        if(record.status==1){
            this.setState({ switchchecked: true})
        }
        if(record.status==0){
            this.setState({ switchchecked: false})
        }
        
        axios
            .post(baseUrl+"/api/bot-status-change", {id: record.id,status:this.state.switchchecked})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                   this.getData();
                }
            })
            .catch();
        this.getData();
    }

    componentDidMount() {
        if (this.props.auth.user.botManagement !==undefined && this.props.auth.user.botManagement !==1 ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.botManagement !==undefined && nextProps.auth.user.botManagement !==1 ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.botdata !== undefined
            && nextProps.botdata.bot !== undefined
            && nextProps.botdata.bot.data !== undefined
            && nextProps.botdata.bot.data.message !== undefined) {
           
            $('#add-user-modal').modal('hide');
            $('#update-user-modal').modal('hide');
         
            toast(nextProps.botdata.bot.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        this.getData();
    }

    getData() {
        axios
            .get(baseUrl+"/api/bot-data")
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
        axios
            .get(baseUrl+"/api/pair-data")
            .then(res => {
                this.setState({ pairList: res.data})
            })
            .catch()

        axios
            .get(baseUrl+"/api/get-all-user")
            .then(res => {
                this.setState({ userList: res.data})
            })
            .catch()
    }
  
    editRecord(record) {
        this.setState({ currentRecord: record,
            coin_first_id: record.coin_first_id,
            coin_second_id: record.coin_second_id,
        });
    }
    deleteRecordMgs(record) {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Really want to delete this id?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => this.deleteRecord(record)
              },
              {
                label: 'No'
              
              }
            ]
          });
 

    }
    deleteRecord(record) {
        axios
            .post(baseUrl+"/api/bot-delete", {id: record.id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <BOTAddModal pairList={this.state.pairList} userList={this.state.userList}/>
                    <BOTUpdateModal record={this.state.currentRecord} pairList={this.state.pairList} userList={this.state.userList} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                        <h3 className="mt-2 man_hadding mb-3">BOT Management </h3>
                        <div className="white_box">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <button className="btn btn-primary ml-auto d-block float-md-right mb-3 " data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> Add </button>
                           
							
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                  
                    <ToastContainer/>
                </div>  </div>
            </div>
        );
    }

}

BOT.propTypes = {
    auth: PropTypes.object.isRequired,
    botdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    botdata: state.botdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(BOT);