import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addCrypto, clearErrors } from "../../actions/cryptoActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";

class CryptoAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      short_name: "",
      order_sequence: "",
      errors: {},
      coinImage: "",
      is_deposit: 0,
      is_withdrawal: 0,
      is_trade: 0,
      is_show: 0,
      contract_address: "",
      poly_contract_address: "",
      erc20_contract_address: "",
      trc20_contract_address: "",
      coin_type: "token",
      decimal:""
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.errors", nextProps);

    this.setState({
      name: "",
      short_name: "",
      chain: "",
      url: "",
      order_sequence: "",
      is_deposit: "",
      is_withdrawal: "",
      is_trade: "",
      is_show: "",
      is_tab: "",
      is_new_launch: "",
      icon: "",
      contract_address: "",
      poly_contract_address: "",
      erc20_contract_address: "",
      trc20_contract_address: "",
      coin_type: "coin",
      decimal:""
    })
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }
  handleChange = (event) => {
    let { name, value } = event.target;
    console.log("valuefdvfdv", event.target);
    if (event.target.name === "coin_type") {
      this.setState({ chain: "" });
    }
    this.setState({ [name]: value, emptyField: false, errMsg: "" });
  };

  onChange = (e) => {
    if (e.target.name === "currencyImage") {
      var currencyImage = e.target.files[0];

      var fileSize = e.target.files[0].size;
      if (fileSize > 1000000) {
        this.setState({
          invalidImage: "Maximum 1 MB Image allowed.",
          userUploadImage: false,
        });
        return false;
      }
      console.log(this.state.invalidImage);
      if (!currencyImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        this.setState({
          invalidImage: "Please select valid image jpeg,png,gif.",
          userUploadImage: false,
        });
        return false;
      }

      this.setState({
        coinImage: currencyImage,
      });
    }
    if (e.target.name === "coin_type") {
      this.setState({ chain: "" });
    }

    this.setState({ [e.target.id]: e.target.value });
    this.setState({
      errors: {},
    });
  };

  onCryptoAdd = (e) => {
    e.preventDefault();
    console.log("YTest", this.state);

    if (!this.state.name || this.state.name == "") {
      this.setState({ errors: { name: "Please enter name" } });
      //alert("found")
      return
    }
    if (!this.state.short_name || this.state.short_name == "") {
      this.setState({ errors: { short_name: "Please enter sort name" } });
      //alert("found")
      return
    }
    // if (!this.state.chain || this.state.chain == "null" || this.state.chain == "") {
    //   this.setState({ errors:{chain:"Please select chain type"} });
    //   //alert("found")
    //   return 
    // }
    if (!this.state.decimal || this.state.decimal == "null" || this.state.decimal == "") {
      this.setState({ errors: { decimal: "Please Enter Decimal" } });
      //alert("found")
      return
    }

    if (this.state.poly_contract_address == "" && this.state.erc20_contract_address == "" && this.state.trc20_contract_address == "" && this.state.contract_address == "") {
      toast("Please enter any contract address", {
        position: toast.POSITION.TOP_CENTER,
      });
      // this.setState({ errors:{contract_address:"Please enter contract address"} });
      //alert("found")
      return
    }

    if (!this.state.coinImage || this.state.coinImage == "null" || this.state.coinImage == "") {
      this.setState({ errors: { currencyImage: "Please select Image" } });
      //alert("found")
      return
    }





    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.append("currencyImage", this.state.coinImage);
    formData.append("name", this.state.name);
    formData.append("short_name", this.state.short_name);
    // formData.append("chain", this.state.chain);
    formData.append("url", this.state.url);
    formData.append("order_sequence", "1");
    formData.append("is_deposit", this.state.is_deposit);
    formData.append("is_withdrawal", this.state.is_withdrawal);
    formData.append("is_trade", this.state.is_trade);
    formData.append("is_show", this.state.is_show);
    formData.append("contract_address", this.state.contract_address);
    formData.append('poly_contract_address', this.state.poly_contract_address);
    formData.append('erc20_contract_address', this.state.erc20_contract_address);
    formData.append('trc20_contract_address', this.state.trc20_contract_address);
    formData.append('decimal', this.state.decimal);
    this.props
      .addCrypto(formData, config)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  clearProps = () => {
    // Clear errors when the component is about to unmount
    this.props.clearErrors();
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="add-user-modal" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Crypto Currency</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.clearProps}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onCryptoAdd} id="add-user">
                  {/* <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Coin Type</label>
                    </div>
                    <div className="col-md-9">
                      <select
                        value={this.state.coin_type}
                        name="coin_type"
                        onChange={this.handleChange}
                        className="form-control"
                      >

                        <option value="coin">Coin</option>
                        <option value="token">Token</option>
                       
                      </select>
                      
                    </div>
                  </div> */}

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Currency Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        name="name"
                        type="text"
                        error={errors.name}
                        className={classnames("form-control", {
                          invalid: errors.name,
                        })}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Currency Short Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.short_name}
                        id="short_name"
                        name="short_name"
                        type="text"
                        error={errors.short_name}
                        className={classnames("form-control", {
                          invalid: errors.short_name,
                        })}
                      />
                      <span className="text-danger">{errors.short_name}</span>
                    </div>
                  </div>

                  {/* <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="chain">Currency Type</label>
                    </div>
                    <div className="col-md-9"> */}

                      {
                      /*(this.state.coin_type == "coin") ?
                        <input
                          onChange={this.onChange}
                          value={this.state.chain}
                          id="chain"
                          name="chain"
                          type="text"
                          
                          className={classnames("form-control", {
                            invalid: errors.chain,
                          })}
                        />
                        :
                        <select
                          value={this.state.chain}
                          name="chain"
                          onChange={this.handleChange}
                          className="form-control"
                        >
                          <option value="">Select</option>                       
                          <option value="BSC">BSC</option>
                          <option value="ETH">ETH</option>                          
                          <option value="TRX">TRON</option>
                         
                        </select>*/
                      }

                      {/* <span className="text-danger">{errors.chain}</span>
                    </div>
                  </div> */}

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Currency Decimal</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.decimal}
                        id="decimal"
                        name="decimal"
                        type="number"
                        error={errors.decimal}
                        className={classnames("form-control", {
                          invalid: errors.decimal,
                        })}
                      />
                      <span className="text-danger">{errors.decimal}</span>
                    </div>
                  </div>

                  {/* <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="url">Currency Chain Url</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.url}
                        id="url"
                        name="url"
                        type="text"
                        error={errors.url}
                        className={classnames("form-control", {
                          invalid: errors.url,
                        })}
                      />
                      <span className="text-danger">{errors.url}</span>
                    </div>
                  </div> */}
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="url">BEP20 Contract Address</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.contract_address}
                        id="contract_address"
                        name="contract_address"
                        type="text"
                        error={errors.url}
                        className={classnames("form-control", {
                          invalid: errors.contract_address,
                        })}
                      />
                      <span className="text-danger">
                        {errors.contract_address}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="url">Poly Contract Address</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.poly_contract_address}
                        id="poly_contract_address"
                        name="poly_contract_address"
                        type="text"
                        error={errors.url}
                        className={classnames("form-control", {
                          invalid: errors.poly_contract_address,
                        })}
                      />
                      <span className="text-danger">
                        {errors.poly_contract_address}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="url">ERC20 Contract Address</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.erc20_contract_address}
                        id="erc20_contract_address"
                        name="erc20_contract_address"
                        type="text"
                        error={errors.url}
                        className={classnames("form-control", {
                          invalid: errors.erc20_contract_address,
                        })}
                      />
                      <span className="text-danger">
                        {errors.erc20_contract_address}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="url">TRC20 Contract Address</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.trc20_contract_address}
                        id="trc20_contract_address"
                        name="trc20_contract_address"
                        type="text"
                        error={errors.url}
                        className={classnames("form-control", {
                          invalid: errors.trc20_contract_address,
                        })}
                      />
                      <span className="text-danger">
                        {errors.trc20_contract_address}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Currency Image</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.currencyImage}
                        id="currencyImage"
                        name="currencyImage"
                        type="file"
                        error={errors.currencyImage}
                        className={classnames("form-control", {
                          invalid: errors.currencyImage,
                        })}
                        accept="image/png, image/gif, image/jpeg"
                      />

                      <span className="text-danger">
                        {errors.currencyImage}
                      </span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="is_deposit">Deposit</label>
                    </div>
                    <div className="col-md-9">
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            checked={this.state.is_deposit == 1}
                            onChange={this.onChange}
                            value="1"
                            name="is_deposit"
                            type="radio"
                            className="form-check-input"
                            id="is_deposit"
                          />
                          Enabled
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            checked={this.state.is_deposit == 0}
                            onChange={this.onChange}
                            value="0"
                            name="is_deposit"
                            id="is_deposit"
                            type="radio"
                            className="form-check-input"
                          />
                          Suspended
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="is_withdrawal">Withdrawal</label>
                    </div>
                    <div className="col-md-9">
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            checked={this.state.is_withdrawal == 1}
                            onChange={this.onChange}
                            value="1"
                            name="is_withdrawal"
                            type="radio"
                            className="form-check-input"
                            id="is_withdrawal"
                          />
                          Enabled
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            checked={this.state.is_withdrawal == 0}
                            onChange={this.onChange}
                            value="0"
                            name="is_withdrawal"
                            id="is_withdrawal"
                            type="radio"
                            className="form-check-input"
                          />
                          Suspended
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="is_trade">Trade</label>
                    </div>
                    <div className="col-md-9">
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            checked={this.state.is_trade == 1}
                            onChange={this.onChange}
                            value="1"
                            name="is_trade"
                            type="radio"
                            className="form-check-input"
                            id="is_trade"
                          />
                          Enabled
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            checked={this.state.is_trade == 0}
                            onChange={this.onChange}
                            value="0"
                            name="is_trade"
                            id="is_trade"
                            type="radio"
                            className="form-check-input"
                          />
                          Suspended
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="is_show">Is Show</label>
                    </div>
                    <div className="col-md-9">
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            checked={this.state.is_show == 1}
                            onChange={this.onChange}
                            value="1"
                            name="is_show"
                            type="radio"
                            className="form-check-input"
                            id="is_show"
                          />
                          Enabled
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            checked={this.state.is_show == 0}
                            onChange={this.onChange}
                            value="0"
                            name="is_show"
                            id="is_show"
                            type="radio"
                            className="form-check-input"
                          />
                          Suspended
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Order Sequence</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.order_sequence}
                                                id="order_sequence"
                                                name="order_sequence"
                                                type="number"
                                                error={errors.order_sequence}
                                                className={classnames("form-control", {
                                                    invalid: errors.order_sequence
                                                })}/>
                                            <span className="text-danger">{errors.order_sequence}</span>
                                        </div>
                                    </div> */}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="add-user"
                  type="submit"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CryptoAddModal.propTypes = {
  addCrypto: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addCrypto, clearErrors })(
  withRouter(CryptoAddModal)
);
