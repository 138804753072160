import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HelpQueryModal from "../partials/HelpQueryModal";
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from "../../actions/baseUrl";

const baseUrl = myConstList.baseUrl
class RejectReason extends Component {

    constructor(props) {
        super(props);
        this.state = {
            d1: "",
            d2: "",
            d3: "",
            d4: "",
            d5: "",
            type:this.props.match.params.type
        };
       this.getData = this.getData.bind(this);
    }



        getData() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl + "/api/rejectReason-By-Id-Data", { _id:this.props.match.params.id })
            .then(res => {
                this.setState({
                    d1: res.data.d5_rejectResion,
                    d2: res.data.d2_rejectResion,
                    d3: res.data.d3_rejectResion,
                    d4: res.data.d4_rejectResion,
                    d5: res.data.utility_reason
                });
              
            })
            .catch()
        }
    componentDidMount() {
        if (this.props.auth.user.verifyPending !==undefined && this.props.auth.user.verifyPending !==1 ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
     
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.verifyPending !==undefined && nextProps.auth.user.verifyPending !==1 ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
        
    }
  

    render() {
        return (
            <>
               <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    {console.log("rejectjs",this.state.d1)}
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h3 className="mt-2 man_hadding mb-3">Reject Reason</h3>
                            <div className="man_white_box ">
                            {this.state.type==21 &&
                                <p className="rejectReason">{this.state.d1}</p>
                            }
                            {this.state.type==22 &&
                                <p className="rejectReason">{this.state.d2}</p>
                            }
                            {this.state.type==23 &&
                                <p className="rejectReason">{this.state.d3}</p>
                            }
                            {this.state.type==24 &&
                                <p className="rejectReason">{this.state.d4}</p>
                            }
                             {this.state.type==25 &&
                                <p className="rejectReason">{this.state.d5}</p>
                            }
                            
                        </div></div>
                    </div>
                
                </div>
            </div>
            </>

        );
    }

}

RejectReason.propTypes = {
    auth: PropTypes.object.isRequired,
    helpdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    helpdata: state.helpdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(RejectReason);
